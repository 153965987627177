import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'punsarn-media-viewer-youtube',
  templateUrl: './media-viewer-youtube.component.html',
  styleUrls: ['./media-viewer-youtube.component.scss'],
})
export class MediaViewerYoutubeComponent implements OnInit {
  @Input() videoId: string;
  content: string = '<iframe src="//www.youtube.com/embed/{VIDEOID}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

  ngOnInit() {
    document.getElementsByClassName('youtube-wrapper')[0].innerHTML = this.content.replace('{VIDEOID}', this.videoId);
  }
}
